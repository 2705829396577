var $ = require('jquery');
var slick = require('slick-carousel/slick/slick.js');
// var enquire = require('enquire.js/dist/enquire.js');

var init = function () {
  if ($(document.body).hasClass('page-careers')) {
    let sections = [
      // 'header',
      // 'invest',
      // 'join-our-team'
    ];
    
    
    $(window).on('scroll', function(e) {
      if($(window).width() >= 950) {
        window.boltthreads.runParallax(sections);  
      }
      
    })
    
    if($(window).width() >= 950) {
      window.boltthreads.runParallax(sections);
    }

    if($(window).width() >= 768) {
      $('.employee-profile h4').matchHeight();
      $('.employee-profile h3').matchHeight();

    }

    $(window).on('resize', function() {
      if ($(window).width() >= 768) {

        $('.employee-profile h4').matchHeight();
      $('.employee-profile h3').matchHeight();

      }
    }); 

  }

}

function resizeListener () {
  var $lockups = $('.page-careers .slide, .page-careers .lockup-team');

  enquire.register("screen and (min-width: 768px)", {
    match   : function() {
      resizeLockup();
      $(window).on('resize', resizeLockup);

    },
    unmatch : function() {
      $(window).off('resize', resizeLockup);
      $lockups.css({
        'height': 'auto'
      });
    }
  }, true);

  enquire.register("screen and (max-width: 767px)", {
    match   : function() {
      $(window).off('resize', resizeLockup);
      $lockups.css({
        'height': 'auto'
      });
    },
    unmatch : function() {
      $(window).on('resize', resizeLockup);
    }
  });
}

function resizeLockup () {
  var lockup = $('.page-careers').find('.slide'),
  team = $('.page-careers').find('.lockup-team'),
  headerH = 98,
  arrowH = 60,
  diff = headerH + (arrowH/2);

  height = $(window).height() - diff;
  lockup.height(height);
  // team.height(height);
}

module.exports = init;