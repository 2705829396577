var $ = require('jquery');
var jQuery = require('jquery');
var roadmap = require('./plugins/jquery.roadmap.js');
var roadmap = require('./plugins/jquery.matchHeight.js');
// var dotdotdot = require('dotdotdot');
var homepage = require('./homepage.js');
var technology = require('./technology.js');
var news = require('./news.js');
var about = require('./about.js');
var layout = require('./layout.js');
var careers = require('./careers.js');
var mylo = require('./mylo.js');
var silkprotein = require('./silk-protein.js');
var microsilk = require('./microsilk.js');
var beebe = require('./beebe.js');

(function () {

  // Add toggle for Technology FAQ Sections
  $('.faq-question').on('click', function() {
    $(this).parent().toggleClass('faq-active');
    $(this).next().slideToggle();
  })

  // Parallax elements
  window.boltthreads = {
    runParallax: function(sections) {
      let margin = 100;
      if($(window).height() > 800) {
        margin = 0.1 * $(window).height();
      }
      for(let i = 0; i < sections.length; i++) {
        if($('section.' + sections[i]).offset().top - margin < $(window).scrollTop() + $(window).height()) {
          // $('section.' + sections[i] + ' .parallax-container img');
          $('section.' + sections[i] + ' .parallax-item').each(function(index, element) {
            let $elt = $(element);
            let movementStart = $('section.' + sections[i]).offset().top + ($('section.' + sections[i]).height() / 4);
            let movementEnd = $('section.' + sections[i]).offset().top + ($('section.' + sections[i]).height() * .75);
            let midpoint = $('section.' + sections[i]).offset().top + ($('section.' + sections[i]).height() / 2);
            let screenMidpoint = $(window).scrollTop() + ($(window).height() / 2);

            // max = 35, mid = 20 min = 5

            let percent = $elt.data('parallaxmin');  
            // if(screenMidpoint > movementStart) {
              percent = $elt.data('parallaxmin') + (($elt.data('parallaxmax') - $elt.data('parallaxmin')) * ((screenMidpoint - movementStart) / (movementEnd - movementStart)));
            // } 
            $elt.css('bottom', percent+'%'); 
            // $elt.css('transform', 'translateY(-' +(5 + percent)+'%)');  
            if($elt.css('display') == 'none') {
              setTimeout(
                function() {
                  $elt.fadeIn(500);    
                }, 500
                )
              
            }
          });

          // console.log($('section.' + sections[i]).offset().top , $('section.' + sections[i]).height(), ($(window).scrollTop() + $(window).height() - $('section.' + sections[i]).offset().top) / $('section.' + sections[i]).height() )
        }
      }
    }
  }
  layout();
  homepage();
  technology();
  careers();
  about();
  news();
  mylo();
  beebe();
  silkprotein();
  microsilk();

})();
