var $ = require('jquery');
var slick = require('slick-carousel/slick/slick.js');
// var enquire = require('enquire.js/dist/enquire.js');

var init = function () {

  if ($(document.body).hasClass('page-silk-protein')) {

    let sections = [
      'parallax-section-1',
      'parallax-section-3', 
      'parallax-section-benefits',
      'parallax-section-18',
      'parallax-section-vegamour',
      // 'info_section',

      ];

      $(window).on('scroll', function(e) { 
        if($(window).width() >= 950) {
          window.boltthreads.runParallax(sections);  
        }

      })
      if($(window).width() >= 950) {
        window.boltthreads.runParallax(sections);
      }


      var events = [];
      
      $( "#timeline-events .timeline-event" ).each(function( index ) {
        var event= {};
        event.date='';
        event.content=$(this).html();
        events.push(event);
      });


      $('#product-timeline').roadmap(events, {
        eventsPerSlide: 6,
        slide: 1,
        prevArrow: '<i class="material-icons">keyboard_arrow_left</i>',
        nextArrow: '<i class="material-icons">keyboard_arrow_right</i>',
        orientation: 'vertical'

      });


    }
  }


  module.exports = init;