var $ = require('jquery');
var slick = require('slick-carousel/slick/slick.js');
// var enquire = require('enquire.js/dist/enquire.js');

var init = function () {

  if ($(document.body).hasClass('page-about')) {
    
    let sections = [
      'header',
      'info-section'
    ];

    
    $(window).on('scroll', function(e) {
      if($(window).width() >= 950) {
        window.boltthreads.runParallax(sections);  
      }
      
    })

    if($(window).width() >= 950) {
      window.boltthreads.runParallax(sections);
    }

     // Events Sections 
     var events = [];
      
      $("#timeline-events .timeline-event").each(function( index ) {
        var event= {};
        event.date='';
        event.content=$(this).html();
        events.push(event);
      });


      $('#about-timeline').roadmap(events, {
        eventsPerSlide: 7,
        slide: 1,
        prevArrow: '<i class="material-icons"><</i>',
        nextArrow: '<i class="material-icons">></i>',

      });


  }
}

module.exports = init;