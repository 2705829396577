var $ = require('jquery');
// var dotdotdot = require('dotdotdot');

var init = function () {
  if ($(document.body).hasClass('page-news') || $(document.body).hasClass('page-news-single')) {
    let sections = [
    'header',
    ];
    
    
    $(window).on('scroll', function(e) {
      if($(window).width() >= 950) {
        window.boltthreads.runParallax(sections);  
      }
      
    })
    
    if($(window).width() >= 950) {
      window.boltthreads.runParallax(sections);
    }

    if($(window).width() >= 768) {
      $('.featured-article h3').matchHeight();
      $('.featured-article p').matchHeight();

    }

    $(window).on('resize', function() {
      if ($(window).width() >= 768) {

        $('.featured-article h3').matchHeight();
        $('.featured-article p').matchHeight();

      }
    }); 

    // footerPlacer();
    // generateFacebookLink();
    // generateTwitterLink();
    
    // $(window).on('resize', function() {
    //   if ($(window).width() >= 768) {
    //     setTimeout( function() {
    //       $('.headings').css('width', ($('nav.main-menu').outerWidth() + 20) + 'px');
    //       $('.headings').css('opacity', '1');
    //     }, 100);      
    //   }
    // }); 
    
    // if ($(window).width() >= 768) {
    //   setTimeout( function() {
    //     $('.headings').css('width', ($('nav.main-menu').outerWidth() + 20) + 'px');
    //     $('.headings').css('opacity', '1');
    //   }, 100);      
    // }
    
    // $(document).ready(function() {
    //   jQuery('.news-items h4, .featured-article h3, .featured-article p').dotdotdot();  
    // })
    
    // OLD
    // $('.subhead a').on('click', function() {
    //   var $this = $(this);

    //   $('.subhead a').removeClass('active');
    //   $this.addClass('active');

    //   if($this.data('category') == 'all') {
    //     $('.news-item').css('display', 'block');
    //   } else {
    //     $('.news-item').each(function () {
    //       if($(this).hasClass('news-' + $this.data('category'))) {
    //         $(this).css('display', 'block');
    //       } else {
    //         $(this).css('display', 'none');
    //       }
    //     });
    //   }
    // });


  }
}

// function footerPlacer () {
//   $(window).on('resize', function () {
//     var pageHeight = 0;
//     if($(document.body).hasClass('page-news')) {
//       pageHeight = $('.page-news').outerHeight();
//     } else {
//       pageHeight = $('.page-news-single').outerHeight();
//     }
//     if (pageHeight < $(window).height()) {
//       $('footer').css('bottom', 'inherit');
//       $('footer').css('top', ($(window).height() - 97) + 'px');
//     } else {
//       $('footer').css('bottom', '0px');
//       $('footer').css('top', 'inherit');
//     }
//   });

//   $(window).trigger('resize');
// }

// function generateFacebookLink () {
//   $(document.body).on('click', '.fbshare', function (e) {
//     e.preventDefault();
//     window.open('http://www.facebook.com/sharer/sharer.php?u='+document.location.href, 'facebook_share', 'height=320, width=640, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no');
//   });
// }
// function generateTwitterLink () {
//   $('.twshare').attr('href', 'https://twitter.com/intent/tweet?text=I found this on boltthreads.com&url='+document.location.href);
// }

module.exports = init;