var $ = require('jquery');
// var scroller = require('./plugins/scroller.js');
// var scroller = require('onepage-scroll/jquery.onepage-scroll');
// var dotdotdot = require('dotdotdot');
// var hammer = require('./plugins/hammer.min.js');
var bgFadeIn = 1000;
var bgFadeOut = 1000;
var textSlideIn = 400;
var textSlideOut = 400;
var iconFadeIn = 250; 

var init = function () {
  if ($('body').hasClass('page-home')) { 
    
    let sections = [
      'section-1',
      'mylo',
      'microsilk',
      'silk-protein',
      'about-us',
      'environmental-mission'
    ];

    // Animate the articles section
    var articlesStarted = false;
    var animateArticles = function() {
      if(articlesStarted == false) {
        
        if(jQuery('.article-top').offset().top - 100 < $(window).scrollTop() + $(window).height()) {
          articlesStarted = true;
          setTimeout(function() {
            jQuery('#article-0').fadeIn(250);
            if($(window).width() >= 992) {
              jQuery('.more-articles').show();
            }
          }, 500);
          setTimeout(function() {
            jQuery('#article-1').fadeIn(250);  
          }, 1000);
          setTimeout(function() {
            jQuery('#article-2').fadeIn(250);  
          }, 1500);  
        }
      }
    }
    
    // Animate the From > To Section
    var fromToStarted = false;
    var animateFromTo = function() {
      
      if(fromToStarted == false) {
        
        if(jQuery('.from-to-div-container').offset().top - 100 < $(window).scrollTop() + $(window).height()) {
          
          fromToStarted = true;
          var fromItems = jQuery('.from-div p');
          var toItems = jQuery('.to-div p');
          var animateItems = [];
          jQuery(fromItems[0]).show();
          jQuery(toItems[0]).show(); 
          
          for(var i = 1; i < fromItems.length; i++) {
            animateItems.push(fromItems[i]);
            if(i < toItems.length) {
              animateItems.push(toItems[i]);  
            }
          }
          
          // animate
          for(let i = 0; i < animateItems.length; i++) {
            setTimeout(function() {
              jQuery(animateItems[i]).fadeIn(250);
            }, 500 + (500 * i))  
          }
        }
      }
    }
    
    $(window).on('scroll', function(e) { 
      animateArticles();
      animateFromTo();
      if($(window).width() >= 950) {
        window.boltthreads.runParallax(sections);  
      }
      
    })
    if($(window).width() >= 950) {
      window.boltthreads.runParallax(sections);
    }
    
    /* 
    $('#video-container').vide({
      mp4: '/wp-content/themes/boltthreads/dist/images/home-page-video-no-text.mp4',
      webm: '/wp-content/themes/boltthreads/dist/images/home-page-video-no-text.webm',
      poster: '/wp-content/themes/boltthreads/dist/images/home.video.still.jpg'
    }, {
      volume: 1,
      playbackRate: 1,
      muted: true,
      loop: true,
      autoplay: true,
      position: '50% 50%', // Similar to the CSS `background-position` property.
      posterType: 'jpg', // Poster image type. "detect" — auto-detection; "none" — no poster; "jpg", "png", "gif",... - extensions.
      resizing: true, // Auto-resizing, read: https://github.com/VodkaBears/Vide#resizing
      bgColor: 'transparent' // Allow custom background-color for Vide div

    });
    
    $('.video-cta').on('click touchstart', function(e) {
      e.preventDefault();
      $('.lockup iframe').css('display', 'block');
      youtubeVideo.playVideo();
    });
    */
    
    
    /*
    setTimeout(function() {
      $('#news-notification h5').dotdotdot();
      $('#news-notification .excerpt-text').dotdotdot();
    }, 1100);
    */
    // $('footer.site-footer').css('display', 'none');
    /*  
    $('.scroll-cta').on('click touchstart', function(e) {
      e.preventDefault();
      // $('html, body').animate({ scrollTop: 100 }, 50);
      jQuery('.main').moveDown();
    });
    
    $(window).on('resize', resizeHomepage); 
    resizeHomepage();
    */
    /*
    if($(window).width() >= 768) { 
      
      if($(window).width() < 992) {
        // switch the left-right tiles to top bottom
        $('section.first .big-col').addClass('big-row'); 
        $('section.first .big-row').removeClass('big-col');
        $('section.second .big-col').addClass('big-row'); 
        $('section.second .big-row').removeClass('big-col');
        $('section.fourth .big-col').addClass('big-row'); 
        $('section.fourth .big-row').removeClass('big-col');
        $('section.sixth .big-col').addClass('big-row'); 
        $('section.sixth .big-row').removeClass('big-col');
        $('section.seventh .big-col').addClass('big-row'); 
        $('section.seventh .big-row').removeClass('big-col');
        $('section.nineth .big-col').addClass('big-row'); 
        $('section.nineth .big-row').removeClass('big-col');
        $('section.eleventh .big-col').addClass('big-row'); 
        $('section.eleventh .big-row').removeClass('big-col');
      }
      
      $('.scroll-cta').on('click touchstart', function(e) {
        e.preventDefault();
        // $('html, body').animate({ scrollTop: 100 }, 50);
        $('.main').moveDown();
      });
      
      $('footer.site-footer').css('display', 'none');
      
    } else {
      $('body').css('overflow', 'scroll');
      $('footer').css('position', 'relative');
      // switch the left-right tiles to top bottom
      $('section.first .big-col').addClass('big-row'); 
      $('section.first .big-row').removeClass('big-col');
      $('section.second .big-col').addClass('big-row'); 
      $('section.second .big-row').removeClass('big-col');
      $('section.fourth .big-col').addClass('big-row'); 
      $('section.fourth .big-row').removeClass('big-col');
      $('section.sixth .big-col').addClass('big-row'); 
      $('section.sixth .big-row').removeClass('big-col');
      $('section.seventh .big-col').addClass('big-row'); 
      $('section.seventh .big-row').removeClass('big-col');
      $('section.nineth .big-col').addClass('big-row'); 
      $('section.nineth .big-row').removeClass('big-col');
      $('section.eleventh .big-col').addClass('big-row'); 
      $('section.eleventh .big-row').removeClass('big-col');

      $(window).trigger('resize');
    }
    */
    
    // if (show_notification) {
    //   setTimeout(function() {
    //     $('#news-notification').slideDown();
    //   }, 50);
    // }
    
  }
}

function resizeHomepage() {
}

module.exports = init;