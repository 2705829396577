var init = function () {
    var ImprovedNoise = function() {
        function r(r) {
            return r * r * r * (r * (6 * r - 15) + 10)
        }

        function n(r, n, t) {
            return n + r * (t - n)
        }

        function t(r, n, t, u) {
            var o = 15 & r,
                e = 8 > o ? n : t,
                i = 4 > o ? t : 12 == o || 14 == o ? n : u;
            return (0 == (1 & o) ? e : -e) + (0 == (2 & o) ? i : -i)
        }
        for (var u = [151, 160, 137, 91, 90, 15, 131, 13, 201, 95, 96, 53, 194, 233, 7, 225, 140, 36, 103, 30, 69, 142, 8, 99, 37, 240, 21, 10, 23, 190, 6, 148, 247, 120, 234, 75, 0, 26, 197, 62, 94, 252, 219, 203, 117, 35, 11, 32, 57, 177, 33, 88, 237, 149, 56, 87, 174, 20, 125, 136, 171, 168, 68, 175, 74, 165, 71, 134, 139, 48, 27, 166, 77, 146, 158, 231, 83, 111, 229, 122, 60, 211, 133, 230, 220, 105, 92, 41, 55, 46, 245, 40, 244, 102, 143, 54, 65, 25, 63, 161, 1, 216, 80, 73, 209, 76, 132, 187, 208, 89, 18, 169, 200, 196, 135, 130, 116, 188, 159, 86, 164, 100, 109, 198, 173, 186, 3, 64, 52, 217, 226, 250, 124, 123, 5, 202, 38, 147, 118, 126, 255, 82, 85, 212, 207, 206, 59, 227, 47, 16, 58, 17, 182, 189, 28, 42, 223, 183, 170, 213, 119, 248, 152, 2, 44, 154, 163, 70, 221, 153, 101, 155, 167, 43, 172, 9, 129, 22, 39, 253, 19, 98, 108, 110, 79, 113, 224, 232, 178, 185, 112, 104, 218, 246, 97, 228, 251, 34, 242, 193, 238, 210, 144, 12, 191, 179, 162, 241, 81, 51, 145, 235, 249, 14, 239, 107, 49, 192, 214, 31, 181, 199, 106, 157, 184, 84, 204, 176, 115, 121, 50, 45, 127, 4, 150, 254, 138, 236, 205, 93, 222, 114, 67, 29, 24, 72, 243, 141, 128, 195, 78, 66, 215, 61, 156, 180], o = 0; 256 > o; o++) u[256 + o] = u[o];
        return {
            noise: function(o, e, i) {
                var f = ~~o,
                    v = ~~e,
                    a = ~~i,
                    c = 255 & f,
                    s = 255 & v,
                    d = 255 & a;
                o -= f, e -= v, i -= a;
                var m = o - 1,
                    p = e - 1,
                    I = i - 1,
                    N = r(o),
                    b = r(e),
                    g = r(i),
                    h = u[c] + s,
                    j = u[h] + d,
                    k = u[h + 1] + d,
                    l = u[c + 1] + s,
                    q = u[l] + d,
                    w = u[l + 1] + d;
                return n(g, n(b, n(N, t(u[j], o, e, i), t(u[q], m, e, i)), n(N, t(u[k], o, p, i), t(u[w], m, p, i))), n(b, n(N, t(u[j + 1], o, e, I), t(u[q + 1], m, e, i - 1)), n(N, t(u[k + 1], o, p, I), t(u[w + 1], m, p, I))))
            }
        }
    };
    var MersenneTwister = function(t) {
        void 0 == t && (t = (new Date).getTime()), this.N = 624, this.M = 397, this.MATRIX_A = 2567483615, this.UPPER_MASK = 2147483648, this.LOWER_MASK = 2147483647, this.mt = new Array(this.N), this.mti = this.N + 1, this.init_genrand(t)
    };
    MersenneTwister.prototype.init_genrand = function(t) {
        for (this.mt[0] = t >>> 0, this.mti = 1; this.mti < this.N; this.mti++) {
            var t = this.mt[this.mti - 1] ^ this.mt[this.mti - 1] >>> 30;
            this.mt[this.mti] = (1812433253 * ((4294901760 & t) >>> 16) << 16) + 1812433253 * (65535 & t) + this.mti, this.mt[this.mti] >>>= 0
        }
    }, MersenneTwister.prototype.init_by_array = function(t, i) {
        var s, h, n;
        for (this.init_genrand(19650218), s = 1, h = 0, n = this.N > i ? this.N : i; n; n--) {
            var r = this.mt[s - 1] ^ this.mt[s - 1] >>> 30;
            this.mt[s] = (this.mt[s] ^ (1664525 * ((4294901760 & r) >>> 16) << 16) + 1664525 * (65535 & r)) + t[h] + h, this.mt[s] >>>= 0, s++, h++, s >= this.N && (this.mt[0] = this.mt[this.N - 1], s = 1), h >= i && (h = 0)
        }
        for (n = this.N - 1; n; n--) {
            var r = this.mt[s - 1] ^ this.mt[s - 1] >>> 30;
            this.mt[s] = (this.mt[s] ^ (1566083941 * ((4294901760 & r) >>> 16) << 16) + 1566083941 * (65535 & r)) - s, this.mt[s] >>>= 0, s++, s >= this.N && (this.mt[0] = this.mt[this.N - 1], s = 1)
        }
        this.mt[0] = 2147483648
    }, MersenneTwister.prototype.genrand_int32 = function() {
        var t, i = new Array(0, this.MATRIX_A);
        if (this.mti >= this.N) {
            var s;
            for (this.mti == this.N + 1 && this.init_genrand(5489), s = 0; s < this.N - this.M; s++) t = this.mt[s] & this.UPPER_MASK | this.mt[s + 1] & this.LOWER_MASK, this.mt[s] = this.mt[s + this.M] ^ t >>> 1 ^ i[1 & t];
            for (; s < this.N - 1; s++) t = this.mt[s] & this.UPPER_MASK | this.mt[s + 1] & this.LOWER_MASK, this.mt[s] = this.mt[s + (this.M - this.N)] ^ t >>> 1 ^ i[1 & t];
            t = this.mt[this.N - 1] & this.UPPER_MASK | this.mt[0] & this.LOWER_MASK, this.mt[this.N - 1] = this.mt[this.M - 1] ^ t >>> 1 ^ i[1 & t], this.mti = 0
        }
        return t = this.mt[this.mti++], t ^= t >>> 11, t ^= t << 7 & 2636928640, t ^= t << 15 & 4022730752, t ^= t >>> 18, t >>> 0
    }, MersenneTwister.prototype.genrand_int31 = function() {
        return this.genrand_int32() >>> 1
    }, MersenneTwister.prototype.genrand_real1 = function() {
        return this.genrand_int32() * (1 / 4294967295)
    }, MersenneTwister.prototype.random = function() {
        return this.genrand_int32() * (1 / 4294967296)
    }, MersenneTwister.prototype.genrand_real3 = function() {
        return (this.genrand_int32() + .5) * (1 / 4294967296)
    }, MersenneTwister.prototype.genrand_res53 = function() {
        var t = this.genrand_int32() >>> 5,
            i = this.genrand_int32() >>> 6;
        return (67108864 * t + i) * (1 / 9007199254740992)
    };
    var Line, Settings, Stack, animate, frame, m, n, offset, settings, showStats, stack, u;
    u = 7, frame = 0, m = new MersenneTwister, n = new ImprovedNoise, offset = {
        x: 1e4 * m.random() + 1234,
        y: 1e4 * m.random() + 1234
    }, Line = function() {
        function t(t, i, s) {
            this.stack = t, this.start = i, this.s = s, this.res = 0, this.points = [], this.position = {
                x: 0,
                y: 0
            }
        }
        return t.prototype.place = function(t, i, s) {
            var e, n, h, o;
            for (this.start = t, this.res = i, this.step = s, this.position.x = this.start.x, this.position.y = this.start.y, o = [], e = n = 0, h = this.res - 1; h >= 0 ? h >= n : n >= h; e = h >= 0 ? ++n : --n) o.push(this.points[e] = {
                x: this.position.x,
                y: this.position.y
            });
            return o
        }, t.prototype.move = function() {
            var t, i, s, e, h, o, a, r, c;
            for (e = this.position.x, h = this.position.y, s = u + this.s / (10 + 1e3 * this.stack.tension), r = this.points, c = [], o = 0, a = r.length; a > o; o++) t = r[o], t.x = e, t.y = h, i = n.noise((offset.x + e) / 300, (offset.y + h) / 200, s), e -= this.step, c.push(h += .15 * i * this.stack.height);
            return c
        }, t.prototype.renderLine = function() {
            var t, i, s, e;
            for (this.stack.ctx.moveTo(this.points[0].x, this.points[0].y), e = [], t = i = 1, s = this.points.length - 3; s >= 1 ? s >= i : i >= s; t = s >= 1 ? ++i : --i) e.push(this.stack.ctx.lineTo(this.points[t].x, this.points[t].y));
            return e
        }, t.prototype.renderSmoothLine = function() {
            var t, i, s, e, n;
            for (this.stack.ctx.moveTo(this.points[0].x, this.points[0].y), t = e = 1, n = this.points.length - 3; n >= 1 ? n >= e : e >= n; t = n >= 1 ? ++e : --e) i = .5 * (this.points[t].x + this.points[t + 1].x), s = .5 * (this.points[t].y + this.points[t + 1].y), this.stack.ctx.quadraticCurveTo(this.points[t].x, this.points[t].y, i, s);
            return this.stack.ctx.quadraticCurveTo(this.points[t].x, this.points[t].y, this.points[t + 1].x, this.points[t + 1].y)
        }, t
    }(), Stack = function() {
        function t(t) {
            var i, s, e, n, h;
            for (this.num = t, this.alpha = .18, this.offsetSpeed = {
                    x: 1,
                    y: 0
                }, this.enabled = !0, this.paint = "#d1dee6", this.clear = "#3c4a54", this.lines = [], this.tension = .6, this.scale = window.devicePixelRatio, this.tick = 0, this.container = document.getElementById("lockup"), this.canvas = document.createElement("canvas"), this.container.appendChild(this.canvas), this.canvas.style.width = "100%", this.canvas.style.position = "absolute", this.canvas.style.left = "0px", this.canvas.style.top = "0px", this.canvas.style.background = this.clear, e = {
                    x: this.width,
                    y: 0
                }, i = n = 0, h = this.num; h >= 0 ? h >= n : n >= h; i = h >= 0 ? ++n : --n) s = new Line(this, e, i), e.y = m.random() * this.height, this.lines.push(s);
            this.setup(), this.init()
        }
        return t.prototype.setup = function() {
            return this.width = Math.round(window.innerWidth * this.scale), this.height = Math.round(window.innerHeight * this.scale), this.center = {
                x: .5 * this.width,
                y: .5 * this.height
            }, this.canvas.width = this.width, this.canvas.height = this.height, this.ctx = this.canvas.getContext("2d"), this.ctx.strokeStyle = "rgb(209,222,230)", this.ctx.globalAlpha = this.alpha
        }, t.prototype.init = function() {
            var t, i, s, e, n, h, o;
            for (s = {
                    x: this.width,
                    y: 0
                }, i = Math.ceil(this.width / 150) + 3, h = this.lines, o = [], e = 0, n = h.length; n > e; e++) t = h[e], s.y = m.random() * this.height, o.push(t.place(s, i, 150));
            return o
        }, t.prototype.runandrender = function() {
            var t, i, s, e;
            for (this.ctx.clearRect(0, 0, this.width, this.height), offset.x += this.offsetSpeed.x, offset.y += this.offsetSpeed.y, this.ctx.beginPath(), e = this.lines, i = 0, s = e.length; s > i; i++) t = e[i], t.move(), t.renderSmoothLine();
            return this.ctx.stroke(), this.ctx.closePath()
        }, t
    }(), stack = new Stack(window.innerWidth > 500 ? 100 : 50), Settings = function() {
        this.speed = .06, this.backdrop = !1, this.padding = 2, this.hide = !1, this.backdropAlpha = .5, this.fullscreen = function() {
            return screenfull.enabled ? screenfull.toggle() : void 0
        }, this.showFps = !0, this.readme = function() {
            return window.open("README.md")
        }, this.mockup = function() {
            return window.open("img/mock-up.png")
        }, this.source = function() {
            return window.open("js/pattern.coffee")
        }, this.svg = function() {
            return makeSvg()
        }
    }, settings = new Settings, showStats = !1, (animate = function() {
        return frame++, u += settings.speed / 50, stack.enabled && stack.runandrender(), showStats === !0 && stats.update(), requestAnimationFrame(animate)
    })(), window.addEventListener("resize", function() {
        return stack.setup(), stack.init()
    });
}
module.exports = init;