var $ = require('jquery');
// var enquire = require('enquire.js/dist/enquire.js');
// var Bootstrap = require('bootstrap-sass/assets/javascripts/bootstrap.js');

var init = function () {
  $(document.body).on('click', '.cheeseburger', function (e) {
    e.preventDefault();
    $(this).toggleClass('active');
    $('nav.main-menu-mobile').toggleClass('active');
  });
  resizeListener();
}


function setBinds () {
}

function unsetBinds () {
  $(document.body).off('click', '.contact-link', contactLink);
  $(document.body).off('click', '#contact-modal .overlay, #contact-modal .close', closeModal);
}


function contactLink (e) {
    e.preventDefault();
    var $modal = $('#contact-modal');
    if (!$modal.hasClass('active') && !$modal.hasClass('inactive')) {
      // setupMap();
    }
    if ($modal.hasClass('active')) {
      $modal.removeClass('active').addClass('inactive').fadeOut();
    } else {
      $modal.removeClass('inactive').addClass('active').fadeIn();
    }
}

function closeModal (e) {
  e.preventDefault();
  $('#contact-modal').removeClass('active').addClass('inactive').fadeOut();
}

function resizeListener () {

  enquire.register("screen and (min-width: 769px)", {
    match   : function() {
      $(document.body).on('click', '.contact-link', contactLink);
      $(document.body).on('click', '#contact-modal .overlay, #contact-modal .close', closeModal);
    }
  });

  enquire.register("screen and (max-width: 768px)", {
    match   : function() {
      $(document.body).off('click', '.contact-link', contactLink);
      $(document.body).off('click', '#contact-modal .overlay, #contact-modal .close', closeModal);
    }
  }, true);
}


// function setupMap () {
//   // initialize maps

//   var styleOptions = [
//     {
//       "featureType": "landscape",
//       "stylers": [
//         { "color": "#e0e9e3" }
//       ]
//     },{
//       "featureType": "poi",
//       "stylers": [
//         { "color": "#e0e9e3" }
//       ]
//     },{
//       "elementType": "labels.text.fill",
//       "stylers": [
//         { "color": "#4f7b79" }
//       ]
//     },{
//       "elementType": "labels.text.stroke",
//       "stylers": [
//         { "color": "#ffffff" }
//       ]
//     },{
//       "featureType": "road",
//       "elementType": "geometry.fill",
//       "stylers": [
//         { "color": "#5e6f77" }
//       ]
//     },{
//       "featureType": "road",
//       "elementType": "geometry.stroke",
//       "stylers": [
//         { "color": "#71b4c2" }
//       ]
//     },{
//       "featureType": "transit",
//       "elementType": "labels",
//       "stylers": [
//         { "visibility": "off" }
//       ]
//     },{
//       "featureType": "poi",
//       "elementType": "labels",
//       "stylers": [
//         { "visibility": "off" }
//       ]
//     },{
//       "featureType": "transit",
//       "stylers": [
//         { "visibility": "off" }
//       ]
//     },
//     {
//       "featureType": "road",
//       "elementType": "labels.icon",
//       "stylers": [
//         { "visibility": "off" }
//       ]
//     },
//     {
//       "featureType": "water",
//       "stylers": [
//         { "color": "#6f818e" }
//       ]
//     }
//   ];
//   var coords = new google.maps.LatLng(37.8402551, -122.2907745);

//   var mapOptions = {
//       zoom: 15,
//       center: coords,

//       styles: styleOptions,
//       disableDefaultUI: true
//   };

//   var mapEl = document.getElementById('map-boltthreads');

//   var boltthreadsMap = new google.maps.Map(mapEl, mapOptions);

//   var marker = new google.maps.Marker({
//     position: coords,
//     map: boltthreadsMap,
//     title: 'Bolt Threads'
//   });
// }

module.exports = init;