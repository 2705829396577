var $ = require('jquery');
var slick = require('slick-carousel/slick/slick.js');
// var enquire = require('enquire.js/dist/enquire.js');

var init = function () {

  if ($(document.body).hasClass('page-beebe')) {
    console.log('FINGLES')

    let sections = [
    'parallax-section-1',
    'parallax-product-1-section',
    'parallax-product-2-section'
      ];

      $(window).on('scroll', function(e) { 
        if($(window).width() >= 950) {
          window.boltthreads.runParallax(sections);  
        }

      })
      if($(window).width() >= 950) {
        window.boltthreads.runParallax(sections);
      }

    }
  }


  module.exports = init;