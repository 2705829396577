var $ = require('jquery');
var colorchange = require('./plugins/colorchange.js');
var scrollMonitor = require("scrollmonitor/scrollMonitor.js");

var init = function () {
  if ($(document.body).hasClass('page-technology')) {
    lockupResizer();
    // colorchange();
    $(document.body).on('click', '.expand', expandAnswer);

    $('.scroll-cta').on('click', function(e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: ($(window).height() - 92) + 'px' });
    });

    $('.fig-container figure img').each(function () {
      var $this = $(this);

      var elementWatcher = scrollMonitor.create( document.getElementById($this.attr('id')) );

      elementWatcher.fullyEnterViewport(function() {
        $('#' + $this.attr('id')).animate({
          'opacity': '1'
        }, 1000);
      });
      elementWatcher.partiallyExitViewport(function() {
        $('#' + $this.attr('id')).animate({
          'opacity': '0'
        }, 1000);
      });
    });
  }
}

function lockupResizer () {
  var lockup = $('.page-technology').find('#lockup'),
  process = $('.page-technology').find('.process-lockup'),
  headerH = 98,
  arrowH = 60,
  diff = headerH;

  $(window).on('resize', function () {
    height = $(window).height() - diff;
    lockup.height(height);
    process.height(height);
  });

  height = $(window).height() - diff;
  lockup.height(height);
  process.height(height);

}
function expandAnswer (e) {
  e.preventDefault();
  var $cat = $(this).closest('.category');

  if ($cat.hasClass('active')) {
    $cat.removeClass('active');
    $(this).siblings('.answer').slideUp();
  } else {
    $('.category').removeClass('active');
    $('.answer').slideUp();
    $(this).siblings('.answer').slideDown();
    $cat.addClass('active');
  }

}

module.exports = init;